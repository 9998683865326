jQuery(function($) {
  function ciblerChampRecherche() {
    $('#commandes a[href="#recherche"]').click(function() {
      setTimeout(function() {
        $('#woocommerce-product-search-field-0').select();
      }, 500);
    });
  }

  function gererScrollEntete() {
    var entete = $('#en-tete');

    if (entete.length > 0)
      $(window)
        .scroll(function() {
          if ($(this).scrollTop() > 100) entete.addClass('scroll');
          else entete.removeClass('scroll');
        })
        .scroll();
  }

  function initialiser() {
    ciblerChampRecherche();
    gererScrollEntete();
  }

  initialiser();
});
